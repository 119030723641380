import React from "react"
import PageTitle from "../components/subcomponents/PageTitle";
import {TablePaper} from "../components/TablePaper";
import {Box, Button, Divider, Grid} from "@mui/material";
import TableSearch from "../components/subcomponents/TableSearch";
import AddButton from "../components/subcomponents/Buttons/AddButton";
import DownloadIcon from "@mui/icons-material/Download";
import {InnerPaperContainer, ButtonContainer} from "../components/subcomponents/Containers/Containers";
import CustomerService from "../services/CustomerService";
import {CustomerTableColumns} from "../constants";
import TableFactory from "../components/TableFactory";
import ErrorExclamation from "../components/subcomponents/ErrorExclamation";
import {styled} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import DeleteCustomerDialog from "../components/dialogs/DeleteCustomerDialog";

const TableBox = styled(Box)({
  padding: "1.5rem 2rem"
})

export default function CustomerPage() {

  const navigate = useNavigate()

  const [items, setItems] = React.useState<any[]>([])
  const [error, setError] = React.useState(false)
  const [selectedRowIds, setSelectedRowIds] = React.useState<string[]>([]);

  const [searchFilter, setSearchFilter] = React.useState('')

  React.useEffect(() => {
    loadData().then()
  }, [])

  async function loadData() {
    try {
      setItems(await CustomerService.getCustomerTableData())
      setError(false)
    } catch (e) {
      console.error(e)
      setError(true)
    }
  }

  function handleSelectionChange(selectionModel: any) {
    if (selectionModel.length > 1) {
      setSelectedRowIds(selectionModel)
    } else {
      setSelectedRowIds(selectionModel.splice(-1))
    }
  }

  function handleAddClick() {
    navigate("/customers/add")
  }

  function handleUpdateClick() {
    if (selectedRowIds.length !== 1) {
      throw new Error("CustomerId could not be parsed correctly")
    }
    navigate(`/customers/update/${selectedRowIds[0]}`)
  }

  function isUpdateButtonDisabled() {
    return selectedRowIds.length !== 1
  }

  async function fullDeleteCustomer() {
    await CustomerService.deleteCustomer(selectedRowIds[0], false)
  }

  async function safeDeleteCustomer() {
    await CustomerService.deleteCustomer(selectedRowIds[0], true)
  }

  return (
    <div className={"base-container"}>
      <PageTitle>Kunden</PageTitle>
      <Divider/>
      <TablePaper>
        <InnerPaperContainer>

          <Grid container spacing={2} direction={"column"} display={'flex'} justifyContent={"space-between"} width={'100%'}
                sx={{
                  marginTop: 0,
                  paddingTop: 0,
                  paddingLeft: 2,
                  paddingRight: 2,
                  flexFlow: 'row wrap'
                }}>
            <Grid display={'flex'} flexWrap={'unset'} flexDirection={'column'} width={'50%'}>
              <div style={{display: 'flex', justifyContent: 'left'}}>
                <TableSearch onSearch={(input) => {
                  setSearchFilter(input)
                }}/>
              </div>
            </Grid>

            <Grid display={'flex'} flexWrap={'unset'} flexDirection={'column'} width={'50%'}>
              <div style={{display: 'flex', justifyContent: 'right' }}>
                <AddButton onClick={handleAddClick}/>
                <Button
                  variant="contained"
                  startIcon={<DownloadIcon/>}
                >CSV</Button>
              </div>
            </Grid>

          </Grid>

        </InnerPaperContainer>
        <TableBox>
          {error ?
            <ErrorExclamation message={"Failed to fetch data"}/>
            : TableFactory.createTable(
              "customers",
              CustomerTableColumns.map(elem => elem.field),
              items.filter((elem) => {
                return Object.values(elem).some((value: any) => {
                    if (value === undefined) {
                      return false
                    }
                    return value.toString().toLowerCase().includes(searchFilter.toLowerCase())
                });
              }),
              selectedRowIds,
              handleSelectionChange,
              { sortModel: [{field: 'name', sort: "asc"}] },
            )}
        </TableBox>
        <ButtonContainer>
          <DeleteCustomerDialog
            fullDeleteHandler={fullDeleteCustomer}
            safeDeleteHandler={safeDeleteCustomer}
            disabled={isUpdateButtonDisabled()}
            refreshFunction={loadData}
          />
          <Button
            variant="contained"
            disabled={isUpdateButtonDisabled()}
            onClick={handleUpdateClick}
          >
            Bearbeiten
          </Button>
        </ButtonContainer>
      </TablePaper>
    </div>
  )
}
