import {CustomerFormValues} from "../components/forms/UpdateCustomerForm";
import {
  addCustomer,
  deleteCustomer,
  getCustomerById,
  getCustomers,
  updateCustomer
} from "../api/customers";
import {DbCustomer} from "sharedlibs";
import {CustomerTableData} from "../models/CustomerTableData";

export default class CustomerService {

  static async getAllCustomers(): Promise<DbCustomer[]> {
    const customers = await getCustomers()

    if (!Array.isArray(customers)) {
      throw new Error("Fetched Customers are not an array")
    }

    return customers
  }

  static async getCustomerById(id: string): Promise<DbCustomer> {
    return await getCustomerById(id)
  }

  static async addCustomer(formObject: CustomerFormValues) {
    const customer = this.createNewCustomer(formObject)

    if (!(await this.checkUniqueProperties(customer))) {
      alert("Some values which must be unique are not unique! Customer will not be added!")
      throw new Error("Customer values are not unique!")
    }

    console.log('Validation passed. Adding customer')
    await addCustomer(customer)
  }

  static async updateCustomer(oldCustomer: DbCustomer, formObject: CustomerFormValues) {
    const updatedCustomer = this.updateCustomerObject(oldCustomer, formObject)

    if (!(await this.checkUniqueProperties(updatedCustomer))) {
      alert("Some values which must be unique are not unique! Customer will not be updated!")
      throw new Error("Customer values are not unique!")
    }

    console.log('Validation passed. Updating customer')
    await updateCustomer(updatedCustomer)
  }

  static async deleteCustomer(customerId: string, safe = true) {
    await deleteCustomer(customerId, safe)
  }

  static async getCustomerTableData(): Promise<CustomerTableData[]> {
    const customers: DbCustomer[] = await this.getAllCustomers()
    return customers.map((elem) => {
      if (!elem._id) {
        throw new Error(`Data field: ${elem} does not contain ._id parameter. \n` +
          "Without the identifier, the object cannot be updated or deleted")
      }
      return {
        _id: elem._id,
        name: elem.name,
        doctorName: elem.doctorName,
        customerNumber: elem.customerNumber,
        postalCode: elem.address.postalCode,
        location: elem.address.location,
        street: elem.address.street,
        system: elem.system,
        biotronikClientName: elem.biotronikClientName,
        biotronikCustomerNumber: elem.biotronikCustomerNumber,
        eTin: elem.eTin,
        biotronikImplantPrice: Number(elem.biotronikImplantPrice),
        biotronikExternalSensorsPrice: Number(elem.biotronikExternalSensorsPrice),
        bostonSAPID: elem.bostonSAPID,
      }
    })
  }

  static mapCustomerObjectToFormObject(customerObject: any): CustomerFormValues {
    return {
      name: customerObject.name,
      doctorName: customerObject.doctorName,
      customerNumber: customerObject.customerNumber,
      streetAndDwellingNumber: customerObject.address.street,
      zip: customerObject.address.postalCode,
      location: customerObject.address.location,
      biotronikClientName: customerObject.biotronikClientName,
      biotronikCustomerNumber: customerObject.biotronikCustomerNumber,
      eTin: customerObject.eTin,
      biotronikImplantPrice: customerObject.biotronikImplantPrice,
      biotronikExternalSensorsPrice: customerObject.biotronikExternalSensorsPrice,
      bostonSAPID: customerObject.bostonSAPID,
      abbottId: '', //TODO add this in backend
      microportId: '', //TODO add this in backend
      system: customerObject.system
    }
  }

  private static createNewCustomer(formObject: CustomerFormValues): DbCustomer {
    return {
      name: formObject.name,
      doctorName: formObject.doctorName,
      customerNumber: formObject.customerNumber,
      address: {
        street: formObject.streetAndDwellingNumber,
        postalCode: formObject.zip,
        location: formObject.location
      },
      biotronikClientName: formObject.biotronikClientName,
      biotronikCustomerNumber: formObject.biotronikCustomerNumber,
      eTin: formObject.eTin,
      biotronikImplantPrice: parseFloat(formObject.biotronikImplantPrice),
      biotronikExternalSensorsPrice: parseFloat(formObject.biotronikExternalSensorsPrice),
      bostonSAPID: formObject.bostonSAPID,
      exportCustomer: true, // TODO at the moment not changeable
      location: formObject.location, // TODO unnecessary remove later
      system: formObject.system,
    } // TODO add the missing form fields: abbottId, microportId, system
  }

  private static updateCustomerObject(customer: DbCustomer, formObject: CustomerFormValues): DbCustomer {
    if (customer._id === undefined) {
      throw new Error("To update a customer, the _id property must exist")
    }

    return {
      _id: customer._id,
      address: {
        street: formObject.streetAndDwellingNumber,
        postalCode: formObject.zip,
        location: formObject.location
      },
      biotronikClientName: formObject.biotronikClientName,
      biotronikCustomerNumber: formObject.customerNumber,
      biotronikImplantPrice: parseFloat(formObject.biotronikImplantPrice),
      biotronikExternalSensorsPrice: parseFloat(formObject.biotronikExternalSensorsPrice),
      bostonSAPID: formObject.bostonSAPID,
      customerNumber: formObject.customerNumber,
      doctorName: formObject.doctorName,
      eTin: formObject.eTin,
      exportCustomer: customer.exportCustomer,
      location: formObject.location,
      name: formObject.name,
      system: formObject.system
    }
  }

  /**
   * This function checks properties which must be unique in the Customer object.
   * Returns true if values are unique, false otherwise.
   * @param customer
   */
  static async checkUniqueProperties(customer: DbCustomer) {
    const result = await getCustomers()
    const notUnique = result.find((elem: any) => {
      return (elem.name === customer.name || elem.customerNumber === customer.customerNumber) && elem._id !== customer._id
    })
    return !notUnique
  }
}
